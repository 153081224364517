import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DownloadPriceReportApiRequest } from './pricing/models/DownloadPriceReportApiRequest';
import { LookupResponseModel } from './pricing/models/LookupResponseModel';
import { PfiLevelAdjustmentsApiRequest } from './pricing/models/PfiLevelAdjustmentsApiRequest';
import { PfiLevelAdjustmentModel } from './pricing/models/pfiLevelAdjustmentModel';
import { GetPfiAdjustmentFactorResponseModel } from './pricing/models/GetPfiAdjustmentFactorResponseModel';
import { SubmitPfiLevelAdjustmentResponseModel } from './pricing/models/SubmitPfiLevelAdjustmentResponseModel';
import { GetPfiListByOrgRequestModel } from './pricing/models/getPfiListByOrgRequestModel';
import { GetPfiResponseModel } from './pricing/models/GetPfiResponseModel';
import { GetPricingAdjustmentFactorsApiRequest } from './pricing/models/GetPricingAdjustmentFactorsApiRequest';
import { SubmitPricingAdjustmentFactorsRequestModel } from './pricing/models/SubmitPricingAdjustmentFactorsRequestModel';
import { IGetProductDevlieryTypesBySource, IGetProductResponse, IwithinBusinessHoursResponse } from './Pricing/models/GetProductsApiResponse';
import { IGetProductRemittanceBySourceRes } from './Pricing/models/IGetproductRemittanceBySources';
import { IdownloadPriceReportRequestModel } from "./Pricing/models/IdownloadPriceReportRequestModel.1";
import { IpfiNumberChangedEventReq } from "./Pricing/models/IpfiNumberChangedEventReq";
import { getPricingScheduleReq } from "./Pricing/models/getPricingScheduleReq";
import { downloadTmpReq } from "./Pricing/models/downloadTmpReq";
import { getProductDevlieryTypesBySourceReq } from "./Pricing/models/getProductDevlieryTypesBySourceReq";
import { getproductremittancebysourceReq } from "./Pricing/models/getproductremittancebysourceReq";
import { IGetProductsRequest } from "./Pricing/models/IGetProductsRequest";
import { IGetXtraSrpPricingRequestModel } from './Pricing/models/IGetXtraSrpPricingRequestModel';
import { IGetSrpPricingResponseModel } from './Pricing/models/IGetSrpPricingResponseModel';
import { IGetSrpServicersResponseModel } from './Pricing/models/IGetSrpServicersResponseModel';
import { IDownloadSRPPriceReportRequestModel } from './Pricing/models/IDownloadSRPPriceReportRequestModel';
import { IFileMissingRequestModel } from './Pricing/models/IFileMissingRequestModel';
import { IGetTradSrpPricingRequestModel } from './Pricing/models/IGetTradSrpPricingRequestModel';
import { IGetGovtMbsRequestModel } from './Pricing/models/IGetGovtMbsRequestModel';
import { IGetSrpPricingRequestModel } from './Pricing/models/IGetSrpPricingRequestModel';
import { IDownloadPriceReportRequestModel } from './Pricing/models/IDownloadPriceReportRequestModel';
import { IFileMissingResponseModel } from './Pricing/models/IFileMissingResponseModel';
import { IMpfBankPricingScheduleModel } from './Pricing/models/IMpfBankPricingScheduleModel';
import { IGetMpfBankPricingScheduleResponseModel } from './Pricing/models/IGetMpfBankPricingScheduleResponseModel';
import { IGetMpfScheduleUploadRequestModel } from './Pricing/models/IGetMpfScheduleUploadRequestModel';
import { AppConfigService } from '@app-shared/services';
import { DownloadLLPAApiRequest } from './pricing/models/DownloadLLPAApiRequest';
import { GeneratePriceReportRequestModel } from './Pricing/models/GetPriceReportRequestModel';
import { GetPriceReportAPIResponseModel } from './Pricing/models/GetPriceReportAPIResponseModel ';
import { CancelPriceReportGenerationRequestModel } from './Pricing/models/CancelPriceReportGenerationRequestModel';
import { CancelPriceReportGenerationResponseModel } from './Pricing/models/CancelPriceReportGenerationResponseModel';

@Injectable({
    providedIn: 'root'
})
export class PricingService {
    //Microservice BaseURL
    private MICROSERVICE_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

    private BUILT_API_PATH = `${this.MICROSERVICE_PATH}/api/pricing/ext`
    private API_SERVICE_PATH = `${this.BUILT_API_PATH}/pricing`;
    private API_PRODUCT_SERVICE_PATH = `${this.BUILT_API_PATH}/product`;

    private MICROSERVICE_DC_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;
    private BUILT_DC_API_PATH = `${this.MICROSERVICE_DC_PATH}/api/deliverycommitment/ext`;
    private API_DC_SERVICE_EXT_PATH = `${this.BUILT_DC_API_PATH}/deliverycommitment`; 

    private MICROSERVICE_ORG_PATH = `${AppConfigService.Settings.Mpf.ApiEndpoint}`;

    private BUILT_ORG_API_PATH = `${this.MICROSERVICE_ORG_PATH}/api/organization/ext`;
    private API_ORG_SERVICE_EXT_PATH = `${this.BUILT_ORG_API_PATH}/organization`;
   
    private options: object = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private websocketOptions(connectionId: string) { return { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'connectionid': `${connectionId}` }) } }

    constructor(private http: HttpClient) {

    }

    getPricingAdjustmentFactors(requestModel: GetPricingAdjustmentFactorsApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'getPricingAdjustmentFactors'.toLowerCase()}`, requestModel)
            .pipe(map(response => response)
            );
    }

    submitPricingAdjustmentFactors(requestModel: SubmitPricingAdjustmentFactorsRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'submitPricingAdjustmentFactors'.toLowerCase()}`, requestModel, this.options)
            .pipe(map(response => response)
            );
    }
    downloadIbpFile(request: DownloadPriceReportApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'downloadIbpFile'.toLowerCase()}`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
    downloadPriceReport(request: IdownloadPriceReportRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadpricereport`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
    getAllHLBCode(): Observable<LookupResponseModel> {
        return this.http.get<LookupResponseModel>(`${this.API_ORG_SERVICE_EXT_PATH}/${'getAllHlb'.toLowerCase()}`, this.options)
            .pipe(map(response => response)
            );
    }
    getHLBPFIContacts(requestModel): Observable<any> {
        return this.http.post<any>(`${this.API_SERVICE_PATH}/${'getHLBPFIContacts'.toLowerCase()}`, requestModel)
            .pipe(map(response => response)
            );
    }
    getPfiListByOrg(getPfiListByOrgRequestModel: GetPfiListByOrgRequestModel): Observable<GetPfiResponseModel> {
        return this.http.post<GetPfiResponseModel>(`${this.API_ORG_SERVICE_EXT_PATH}/${'getPfiListByOrg'.toLowerCase()}`, getPfiListByOrgRequestModel)
            .pipe(map(response => response)
            );
    }
    downloadPfiLevelAdjustments(pfiLevelAdjustmentsApiRequest: PfiLevelAdjustmentsApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'downloadPfiLevelAdjustments'.toLowerCase()}`, pfiLevelAdjustmentsApiRequest, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
    viewPfiLevelAdjustment(pfiLevelAdjustmentsApiRequest: PfiLevelAdjustmentsApiRequest): Observable<GetPfiAdjustmentFactorResponseModel> {
        return this.http.post<GetPfiAdjustmentFactorResponseModel>(`${this.API_SERVICE_PATH}/${'viewPfiLevelAdjustment'.toLowerCase()}`, pfiLevelAdjustmentsApiRequest)
            .pipe(map(response => response)
            );
    }
    submitPfiLevelAdjustment(pfiLevelAdjustmentModel: PfiLevelAdjustmentModel, pfiLevelAdjustmentFiles: any): Observable<SubmitPfiLevelAdjustmentResponseModel> {
        const formData = new FormData();

        //add upload metadata as model to the form data
        formData.append('model', JSON.stringify(pfiLevelAdjustmentModel));
        //add all of the assigned files
        if (pfiLevelAdjustmentFiles.length > 0) {
            formData.append('pfiLevelAdjustmentFile', pfiLevelAdjustmentFiles[0]);
        }
        let headers = new HttpHeaders();
        headers.set('Content-Type', "multipart/form-data");
        return this.http.post<SubmitPfiLevelAdjustmentResponseModel>(`${this.API_SERVICE_PATH}/${'submitPfiLevelAdjustment'.toLowerCase()}`, formData, { headers })
            .pipe(map(response => response));
    }

    submitPfiLevelAdjustmentWebsocket(connectionId: string,pfiLevelAdjustmentModel: PfiLevelAdjustmentModel, pfiLevelAdjustmentFiles: any): Observable<SubmitPfiLevelAdjustmentResponseModel> {
        const formData = new FormData();

        //add upload metadata as model to the form data
        formData.append('model', JSON.stringify(pfiLevelAdjustmentModel));
        //add all of the assigned files
        if (pfiLevelAdjustmentFiles.length > 0) {
            formData.append('pfiLevelAdjustmentFile', pfiLevelAdjustmentFiles[0]);
        }
        const customHttpOptions = {
            headers: new HttpHeaders({
              'connectionid': `${connectionId}`
            })
           };
        return this.http.post<SubmitPfiLevelAdjustmentResponseModel>(`${this.API_SERVICE_PATH}/${'submitPfiLevelAdjustment'.toLowerCase()}`, formData, customHttpOptions)
            .pipe(map(response => response));
    }

    validateMpfPricingScheduleWebsocket(connectionId: string, mpfBankPricingScheduleModel: IMpfBankPricingScheduleModel, bankPricingScheduleFiles: any): Observable<IGetMpfBankPricingScheduleResponseModel> {
        const formData = new FormData();
 
        //add upload metadata as model to the form data
        formData.append('model', JSON.stringify(mpfBankPricingScheduleModel));
        //add all of the assigned files
        if (bankPricingScheduleFiles.length > 0) {
            formData.append('bankPricingScheduleFile', bankPricingScheduleFiles[0]);
        }
        const customHttpOptions = {
            headers: new HttpHeaders({
              'connectionid': `${connectionId}`
            })
           };
        return this.http.post<IGetMpfBankPricingScheduleResponseModel>(`${this.API_SERVICE_PATH}/validatempfpricingschedule`, formData, customHttpOptions )
            .pipe(map(response => response));
    }

    validateMpfOneTimePricingSchedule(mpfBankPricingScheduleModel: IMpfBankPricingScheduleModel, bankPricingScheduleFiles: any): Observable<any> {
        const formData = new FormData();

        //add upload metadata as model to the form data
        formData.append('model', JSON.stringify(mpfBankPricingScheduleModel));
        //add all of the assigned files
        if (bankPricingScheduleFiles.length > 0) {
            formData.append('bankOneTimePricingScheduleFile', bankPricingScheduleFiles[0]);
        }
        let headers = new HttpHeaders();
        headers.set('Content-Type', "multipart/form-data");
        return this.http.post<any>(`${this.API_SERVICE_PATH}/${'validateMpfOneTimePricingSchedule'.toLowerCase()}`, formData, { headers })
            .pipe(map(response => response));
    }
    validateMpfOneTimePricingScheduleWebsocket(connectionId: string, mpfBankPricingScheduleModel: IMpfBankPricingScheduleModel, bankPricingScheduleFiles: any): Observable<any> {
        const formData = new FormData();
 
        //add upload metadata as model to the form data
        formData.append('model', JSON.stringify(mpfBankPricingScheduleModel));
        //add all of the assigned files
        if (bankPricingScheduleFiles.length > 0) {
            formData.append('bankOneTimePricingScheduleFile', bankPricingScheduleFiles[0]);
        }
        const customHttpOptions = {
            headers: new HttpHeaders({
              'connectionid': `${connectionId}`
            })
           };
        return this.http.post<any>(`${this.API_SERVICE_PATH}/validatempfonetimepricingschedule`, formData, customHttpOptions )
            .pipe(map(response => response));
    }

    submitMpfPricingSchedulesWebsocket(connectionId: string, requestModel: IGetMpfScheduleUploadRequestModel): Observable<IGetMpfBankPricingScheduleResponseModel> {
        
        console.log("request for submitmpfpricingschedules", JSON.stringify( requestModel));
        return this.http.post<IGetMpfBankPricingScheduleResponseModel>(`${this.API_SERVICE_PATH}/submitmpfpricingschedules`, requestModel, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }
    stopAllSchedules(requestModel): Observable<any> {
        return this.http.post<any>(`${this.API_SERVICE_PATH}/${'stopAllSchedules'.toLowerCase()}`, requestModel, this.options)
            .pipe(map(response => response)
            );
    }
    getProducts(req: IGetProductsRequest): Observable<IGetProductResponse> {
        return this.http.post<IGetProductResponse>(`${this.API_PRODUCT_SERVICE_PATH}/getproducts`, req)
            .pipe(map(response => response)
            );

    }
    getProductRemittanceBySource(req: getproductremittancebysourceReq): Observable<IGetProductRemittanceBySourceRes> {
        return this.http.post<IGetProductRemittanceBySourceRes>(`${this.API_SERVICE_PATH}/getproductremittancebysource`, req)
            .pipe(map(response => response)
            );

    }
    getProductDevlieryTypesBySource(req: getProductDevlieryTypesBySourceReq): Observable<IGetProductDevlieryTypesBySource> {
        const endpointMethodName = `getproductdeliverytypesbysource`;
        return this.http.post<IGetProductDevlieryTypesBySource>(`${this.API_SERVICE_PATH}/${endpointMethodName}`, req)
            .pipe(map(response => response)
            );

    }
    withinBusinessHours(): Observable<IwithinBusinessHoursResponse> {
        return this.http.post<IwithinBusinessHoursResponse>(`${this.API_DC_SERVICE_EXT_PATH}/validatebusinesshoursfordccreation`, this.options)
            .pipe(map(response => response)
            );
    }


    downloadTpmPrices(request: downloadTmpReq): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'downloadTpmPrices'.toLowerCase()}`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    getPricingScheduleWebsocket(connectionId: string, req: getPricingScheduleReq): Observable<any> {
        return this.http.post<any>(`${this.API_SERVICE_PATH}/getpricingschedule`, req, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

    getPricingScheduleList(req: getPricingScheduleReq): Observable<any> {
        return this.http.post<any>(`${this.API_SERVICE_PATH}/getpricingschedulelist`, req, this.options)
            .pipe(map(response => response)
            );
    }

    CheckPFIPricingAdjustmentFactors(req: IpfiNumberChangedEventReq): Observable<GetPfiResponseModel> {
        return this.http.post<GetPfiResponseModel>(`${this.API_SERVICE_PATH}/${'CheckPFIPricingAdjustmentFactors'.toLowerCase()}`, req, this.options)
            .pipe(map(response => response)
            );
    }

    getSrpServicers(): Observable<IGetSrpServicersResponseModel> {
        return this.http.get<IGetSrpServicersResponseModel>(`${this.API_SERVICE_PATH}/${'getSrpServicers'.toLowerCase()}`, this.options)
            .pipe(map(response => response)
            );
    }

    //Created for Websocket Api Call with Connectionid
    generateAllSRPFilesWebsocket(connectionId: string, request: IDownloadSRPPriceReportRequestModel): Observable<IFileMissingResponseModel> {
        return this.http.post<IFileMissingResponseModel>(`${this.API_SERVICE_PATH}/generateallsrpfiles`, request, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

     //Created for Websocket Api Call with Connectionid
     generatePriceReport(connectionId: string, request: GeneratePriceReportRequestModel): Observable<GetPriceReportAPIResponseModel> {
        return this.http.post<GetPriceReportAPIResponseModel>(`${this.API_SERVICE_PATH}/generatepricereport`, request, this.websocketOptions(connectionId))
            .pipe(map(response => response)
            );
    }

      //Created for cancelling Report Generation
      cancelpricereportgeneration(request: CancelPriceReportGenerationRequestModel): Observable<CancelPriceReportGenerationResponseModel> {
        return this.http.post<CancelPriceReportGenerationResponseModel>(`${this.API_SERVICE_PATH}/cancelpricereportgeneration`, request, this.options)
            .pipe(map(response => response)
            );
    }

    downloadAllSrpPrices(request: IFileMissingRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'downloadAllSrpPrices'.toLowerCase()}`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    getSrpPrices(req: IGetXtraSrpPricingRequestModel): Observable<IGetSrpPricingResponseModel> {
        return this.http.post<IGetSrpPricingResponseModel>(`${this.API_SERVICE_PATH}/getsrpprices`, req, this.options)
            .pipe(map(response => response)
            );
    }

    getTradSrpPrices(req: IGetTradSrpPricingRequestModel): Observable<IGetSrpPricingResponseModel> {
        return this.http.post<IGetSrpPricingResponseModel>(`${this.API_SERVICE_PATH}/gettradsrpprices`, req, this.options)
            .pipe(map(response => response)
            );
    }

    getGovtMbsSrpPrices(req: IGetGovtMbsRequestModel): Observable<IGetSrpPricingResponseModel> {
        return this.http.post<IGetSrpPricingResponseModel>(`${this.API_SERVICE_PATH}/getgovtmbssrpprices`, req, this.options)
            .pipe(map(response => response)
            );
    }

    downloadSrpPrices(request: IGetSrpPricingRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadsrpprices`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    downloadSrpPricesPDF(request: IGetSrpPricingRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadsrppricespdf`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }


    downloadExcelVersion(request: IDownloadPriceReportRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadexcelversion`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }

    downloadPrintVersion(request: IDownloadPriceReportRequestModel): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/downloadprintversion`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
    downloadFile(request: DownloadLLPAApiRequest): Observable<any> {
        return this.http.post(`${this.API_SERVICE_PATH}/${'downloadFile'.toLowerCase()}`, request, { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map(response => response)
            );
    }
}
